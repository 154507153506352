import { PropsWithChildren, useContext } from "react";
import { Caption1, Spinner, ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { PPAContext } from "context/ppa-context";

const layoutStyles = makeStyles({
    mainWrapper: {
        ...shorthands.padding('5em', '0', '0', '0'),
    },
});

export default function Initialising(props: any) {
    const styles = layoutStyles();

    const ppaContext = useContext(PPAContext);

    // TODO: Should always be initialising in here.
    // ctx to have initialisingError
    // Tenant is disabled
    // Licence has expired

    return (
        <div className={styles.mainWrapper}>
            <Spinner
                labelPosition="below"
                label="Initialising..." />
        </div>
    );
}